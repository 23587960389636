const Loader = () => {
    return (
        <div className='loader'>
            <div className="loadingio-spinner-rolling-r4ica76o3a"><div className="ldio-di1781f60h">
                <div></div>
            </div></div>
        </div>
    )
}

export default Loader;